/* -------------------------- */
/*        Core Styles         */
/* -------------------------- */
/* Mobile */
@media only screen and (min-width: 0em) {
  :root {
    --primary: #ABDEFF;
    --primary-1: #07234D;
    --primary-2: #ABDEFF;
    --primaryShade: #DBA907;
    --primaryDark: #013D6E;
    --bodyTextColor: #454E6E;
    --backround-1: #F0F7FD;
    --backround-2: #E3F4FF;
    --backround-3: #EAF2F8;
    --bodyTextColorWhite: #FAFBFC;
    --headerColor: #07234D;
    --gray-1: #575757;
    --footer: #1C1C1C;
    /* 13px - 16px */
    --topperFontSize: clamp(1.3rem, 1.6vw, 1.6rem);
    /* 31px - 49px */
    --headerFontSize: clamp(3.1rem, 4.5vw, 4.9rem);
    --verticalPadding: clamp(5.6rem, 15vw, 14rem);
    /* --verticalPadding: clamp(6rem, 13.2vw, 17rem); */
    --horizontalPadding: clamp(1.6rem, 3.2vw, 3.2rem);
    --sectionPadding: var(--verticalPadding) var(--horizontalPadding);
    --shadow-md: 0 4px 6px -1px rgba(58, 58, 58, 0.06);
    --shadow-large: 0 10px 19.4px -3px rgba(0, 0, 0, 0.2);
    --shadow-card: 0px 12px 34px rgba(14, 17, 44, 0.102);
    --footer-text-color: #FFFFFF;
    --footer-bg-color: #23334C;
    --footer-hover-color: #FFFFFF;
    /* -------------------------- */
    /*         Text styles        */
    /* -------------------------- */
    /* P 1 */
    --text-p1: clamp(1.6rem, 2vw, 2rem) / 3.2em;
  }
  /* inter-regular - latin */
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/inter-v18-latin-regular.woff2') format('woff2');
  }
  /* inter-600 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/inter-v18-latin-600.woff2') format('woff2');
  }
  /* inter-700 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/inter-v18-latin-700.woff2') format('woff2');
  }
  /* inter-800 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url('/assets/fonts/inter-v18-latin-800.woff2') format('woff2');
  }
  /* barlow-semi-condensed-regular - latin */
  @font-face {
    font-display: swap;
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/barlow-semi-condensed-v15-latin-regular.woff2') format('woff2');
  }
  /* barlow-semi-condensed-600 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/barlow-semi-condensed-v15-latin-600.woff2') format('woff2');
  }
  /* barlow-semi-condensed-700 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/barlow-semi-condensed-v15-latin-700.woff2') format('woff2');
  }
  /* barlow-semi-condensed-800 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 800;
    src: url('/assets/fonts/barlow-semi-condensed-v15-latin-800.woff2') format('woff2');
  }
  body,
  html {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    font-family: 'Inter', Arial, sans-serif;
    color: var(--bodyTextColor);
    overflow-x: hidden;
  }
  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    transition: background-color 0.3s;
  }
  .container {
    position: relative;
    width: 92%;
    margin: auto;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: var(--primary-1);
    font-family: 'Barlow Semi Condensed', sans-serif;
    text-transform: uppercase;
    word-break: break-word;
    overflow-wrap: break-word;
  }
  p,
  li,
  a {
    font-size: 1.8rem;
    line-height: 1.5em;
    margin: 0;
  }
  p,
  li {
    color: #575757;
  }
  a:hover,
  button:hover {
    cursor: pointer;
  }
  /* -------------------------- */
  /*       Text styles          */
  /* -------------------------- */
  .cs-topper {
    font-size: clamp(1rem, 2vw, 1.4rem);
    letter-spacing: 0.13rem;
    font-weight: 600;
    padding-bottom: clamp(1.6rem, 4.4vw, 2.4rem);
    text-transform: uppercase;
    color: var(--headerColor);
  }
  .title-h1 {
    font-size: clamp(4rem, 10.6vw, 6.4rem);
    line-height: 1.2em;
    font-weight: 500;
    padding-bottom: clamp(2.4rem, 3vw, 4rem);
  }
  .title-h1-smaller {
    font-size: clamp(3rem, 8vw, 3.8rem);
    line-height: 0px;
  }
  .title-h2 {
    font-size: clamp(3.4rem, 8vw, 5.2rem);
    line-height: 1.2em;
    font-weight: 500;
    padding-bottom: clamp(3.2rem, 8.4vw, 4rem);
  }
  .title-h2-smaller {
    font-size: clamp(2.4rem, 4vw, 3.2rem);
  }
  .title-h3 {
    font-size: clamp(3rem, 8vw, 4rem);
    line-height: 1.2em;
    font-weight: 500;
    padding-bottom: clamp(2.4rem, 6.4vw, 3.2rem);
  }
  .title-h4 {
    font-size: clamp(2rem, 3vw, 2.8rem);
    line-height: 1.2em;
    font-weight: 500;
  }
  .title-h5 {
    font-size: clamp(2rem, 5.3vw, 2.4rem);
    line-height: 1.2em;
    font-weight: 500;
    color: var(--primary-1);
    font-family: 'Barlow Semi Condensed', sans-serif;
    text-transform: uppercase;
  }
  .p1 {
    font-size: clamp(1.7rem, 4vw, 2rem);
    font-weight: 400;
    line-height: 1.8em;
    color: var(--bodyTextColor);
  }
  .p2 {
    font-size: clamp(1.6rem, 4.4vw, 1.8rem);
    font-weight: 400;
    line-height: 1.8em;
    color: var(--bodyTextColor);
  }
  .p3 {
    font-size: clamp(1.4rem, 1.5vw, 1.6rem);
    font-weight: 400;
    line-height: 1.6em;
    color: var(--bodyTextColor);
  }
  .p3-caps {
    font-size: clamp(1.4rem, 1.5vw, 1.6rem);
    text-transform: uppercase;
    letter-spacing: 0.14em;
    color: var(--bodyTextColor);
  }
  .p4 {
    font-size: 1.2rem;
    color: var(--bodyTextColor);
  }
  /* -------------------------- */
  /*         Buttons            */
  /* -------------------------- */
  .button-primary,
  .button-secondary {
    padding-right: 2.4rem;
    padding-left: 2.4rem;
    font-family: 'Barlow Semi Condensed', sans-serif;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: clamp(2.87em, 5.5vw, 3.5em);
    max-width: 100%;
    width: 100%;
    text-decoration: none;
    font-weight: 500;
    color: var(--headerColor);
    padding: 0rem 3.5rem;
    background-color: var(--primary);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    border-radius: 9rem;
    position: relative;
    z-index: 1;
    transition: background-color 0.3s, font-weight 0.3s, transform 0.3s;
  }
  /* BUTTON ICON */
  .button-primary .cs-img {
    height: 100%;
    fill: red;
  }
  .button-secondary {
    border: 1.5px solid;
    background-color: white;
  }
  .button-primary:hover {
    background-color: var(--primaryDark);
    font-weight: bold;
    transform: scale(1.05);
    color: var(--bodyTextColorWhite);
  }
  .button-primary:hover:before {
    width: 100%;
    border-radius: 5px;
  }
  /* BUTTON ICON */
  .button-primary .cs-img {
    height: 100%;
    transition: filter 0.3s ease;
    /* Smooth transition for the color change */
  }
  .button-primary:hover .cs-img {
    filter: brightness(0) invert(1);
    /* Changes the arrow to white */
  }
  .button-secondary:hover {
    background-color: var(--primary);
    font-weight: bold;
    transform: scale(1.05);
  }
  .button-secondary:hover:before {
    width: 100%;
    border-radius: 5px;
  }
  .skip {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1111111;
  }
  /* READ MORE BUTTON */
  .read-more {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .cs-button-link {
    font-size: clamp(1.7rem, 4vw, 2rem);
    font-weight: bold;
    color: var(--bodyTextColor);
  }
  .button-link-img {
    max-width: 2.6rem;
    height: auto;
  }
  .cs-button-link p {
    display: inline;
  }
  .cs-button-link:hover {
    text-decoration: underline;
  }
  /* -------------------------- */
  /*         SHADOWS            */
  /* -------------------------- */
  /* Shadow-md */
  .shadow-md {
    box-shadow: 0 4px 6px -1px rgba(58, 58, 58, 0.6);
  }
  /* -------------------------- */
  /*          PADDINGS            */
  /* -------------------------- */
  .padding-bottom-32px {
    padding-bottom: 3.2rem;
  }
  /* -------------------------- */
  /*          BG COLORS            */
  /* -------------------------- */
  .bg-color {
    background-color: var(--backround-1);
  }
  .bg-color-white {
    background-color: white;
  }
  /* -------------------------- */
  /*          GAPS            */
  /* -------------------------- */
  .gap-24px {
    gap: 2.4rem;
  }
  /* -------------------------- */
  /*         ANIMATIONS          */
  /* -------------------------- */
  /* Apply to all elements with AOS animations */
  [data-aos].aos-animate {
    transform: translateZ(0);
  }
}
/* BUTTON SMALLER ON TABLET and DESKTOP */
@media only screen and (min-width: 768px) {
  .button-primary,
  .button-secondary {
    max-width: 23.8rem;
  }
}
/* Reset Margins */
@media only screen and (min-width: 1024px) {
  body,
  html {
    padding: 0;
    margin: 0;
  }
}
/* -------------------------- */
/*         NAVIGATION          */
/* -------------------------- */
@media only screen and (max-width: 1023px) {
  #navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
    height: auto;
    /* Mobile - Therapiemethoden Dropdown */
  }
  #navigation .background-color-div {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  #navigation .container {
    padding: 1.3rem 0;
    width: 96%;
  }
  #navigation .logo {
    display: inline-block;
    height: 3rem;
    width: auto;
  }
  #navigation .logo img {
    width: auto;
    height: 100%;
  }
  #navigation:before {
    content: "";
    width: 100%;
    height: 0vh;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: height 0.4s, opacity 0.5s;
    backdrop-filter: blur(10px);
  }
  #navigation.open:before {
    height: 100vh;
    opacity: 1;
    z-index: -3;
  }
  #navigation .hamburger-menu {
    position: absolute;
    right: 0rem;
    border: none;
    height: 4.8rem;
    width: 4.8rem;
    z-index: 100;
    display: block;
    background: transparent;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: top 0.3s;
  }
  #navigation .hamburger-menu span {
    height: 2px;
    width: 3rem;
    background-color: #000;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: background-color 0.3s;
    color: white;
  }
  #navigation .hamburger-menu span:before {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: 80%;
    background: #000;
    opacity: 1;
    top: -6px;
    left: 0;
    transition: width 0.3s, left 0.3s, top 0.3s, transform 0.5s;
  }
  #navigation .hamburger-menu span:after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: 80%;
    background: #000;
    opacity: 1;
    top: 6px;
    left: 0;
    transition: width 0.3s, left 0.3s, top 0.3s, transform 0.3s;
  }
  #navigation .hamburger-menu.clicked span {
    background-color: transparent;
  }
  #navigation .hamburger-menu.clicked span:before {
    width: 100%;
    transform: translate(-50%, -50%) rotate(225deg);
    left: 50%;
    top: 50%;
  }
  #navigation .hamburger-menu.clicked span:after {
    width: 100%;
    transform: translate(-50%, -50%) rotate(-225deg);
    left: 50%;
    top: 50%;
  }
  #navigation #navbar-menu {
    position: fixed;
    right: 0;
    padding: 0;
    width: 100%;
    border-radius: 0 0 0.6em 0.6em;
    z-index: -1;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #fff;
    top: 5.8rem;
    padding-top: 0;
    height: 0;
    transition: height 0.3s, padding-top 0.3s, top 0.3s;
    z-index: 9999;
    max-height: 100%;
    max-height: calc(100vh - 5.8rem);
    overflow-y: auto;
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch;
  }
  #navigation #navbar-menu ::-webkit-scrollbar {
    width: 5px;
  }
  #navigation #navbar-menu ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    /* Adjust scrollbar color */
    border-radius: 10px;
  }
  #navigation #navbar-menu ul {
    padding: 0;
    perspective: 700px;
  }
  #navigation #navbar-menu ul li {
    list-style: none;
    margin-bottom: 2.4rem;
    transform-style: preserve-3d;
    opacity: 0;
    transform: translateY(-0.7rem) rotateX(90deg);
    transition: opacity 0.5s, transform 0.5s;
    text-align: left;
  }
  #navigation #navbar-menu ul li:nth-of-type(1) {
    transition-delay: 0.01s;
  }
  #navigation #navbar-menu ul li:nth-of-type(2) {
    transition-delay: 0.02s;
  }
  #navigation #navbar-menu ul li:nth-of-type(3) {
    transition-delay: 0.03s;
  }
  #navigation #navbar-menu ul li:nth-of-type(4) {
    transition-delay: 0.04s;
  }
  #navigation #navbar-menu ul li:nth-of-type(5) {
    transition-delay: 0.05s;
  }
  #navigation #navbar-menu ul li:nth-of-type(6) {
    transition-delay: 0.06s;
  }
  #navigation #navbar-menu ul li:nth-of-type(7) {
    transition-delay: 0.07s;
  }
  #navigation #navbar-menu ul li a {
    text-decoration: none;
    color: var(--headerColor);
    font-size: 1.9rem;
    text-align: center;
    display: inline-block;
    position: relative;
  }
  #navigation #navbar-menu.open {
    height: auto;
    padding-top: 4rem;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 3.2rem;
  }
  #navigation #navbar-menu.open ul li {
    opacity: 1;
    transform: translateY(0) rotateX(0);
    border-bottom: 1px solid rgba(207, 205, 205, 0.5);
    padding-bottom: 1.5rem;
  }
  #navigation #navbar-menu.open .menu-last-item {
    border-bottom: none;
  }
  #navigation #navbar-menu .cs-button-solid {
    background-color: var(--primary-2);
    padding: 1.25rem 3.1rem;
    line-height: 0rem;
    color: white;
  }
  #navigation #navbar-menu .button-primary {
    max-width: 30rem;
    margin-bottom: 3.5rem;
  }
  #navigation #navbar-menu ul li.dropdown {
    position: relative;
    list-style: none;
    padding: 0rem;
    text-align: left;
    margin-bottom: 2.4rem;
    color: red;
    padding-bottom: 2.4rem;
  }
  #navigation #navbar-menu ul li.dropdown .dropdown-toggle {
    font-weight: 600;
  }
  #navigation #navbar-menu ul li.dropdown .dropdown-content {
    display: none;
    background-color: white;
    padding: 0;
    box-shadow: none;
    padding-top: 2rem;
  }
  #navigation #navbar-menu ul li.dropdown .dropdown-content li {
    list-style: none;
    border-bottom: none;
    padding-bottom: 0.5rem;
    margin-bottom: 2.4rem;
  }
  #navigation #navbar-menu ul li.dropdown .dropdown-content li:last-child {
    margin-bottom: 0;
    /* Optional: remove margin from the last <li> */
  }
  #navigation #navbar-menu ul li.dropdown .dropdown-content li a {
    text-decoration: none;
    color: var(--headerColor);
    font-size: 1.6rem;
  }
  #navigation #navbar-menu ul li.dropdown .dropdown-content li a:hover {
    color: var(--primary-2);
  }
  #navigation #navbar-menu ul li.dropdown.open .dropdown-content {
    display: block;
    padding-left: 5rem;
  }
}
/* Desktop - Dropdown */
@media only screen and (min-width: 1024px) {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.6em rgba(0, 0, 0, 0.3);
    background-color: #fff;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #f0f0f0;
  }
  ::-webkit-scrollbar {
    width: 1.2em;
    background-color: #F5F5F5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background: var(--primary);
  }
  body.scroll #navigation .background-color-div {
    height: 90%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  body.scroll #navigation .logo .light {
    display: none;
  }
  body.scroll #navigation .logo .dark {
    display: block;
  }
  body.scroll #navigation #navbar-menu ul li a {
    color: var(--headerColor);
  }
  body.scroll #navigation #navbar-menu ul li a:hover {
    color: var(--primary-2);
  }
  body.scroll #dark-mode-toggle svg path {
    fill: #000;
  }
  body.dark-mode #navigation .background-color-div {
    height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  #navigation {
    width: 100%;
    height: 6.5rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200000;
    padding: 0;
    display: flex;
    align-items: center;
    /* Desktop - Therapiemethoden Dropdown */
  }
  #navigation .background-color-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 0;
    transition: height 0.3s;
  }
  #navigation .container {
    max-width: 122rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  #navigation .logo {
    height: 6rem;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #navigation .logo img {
    width: 13.924rem;
    height: auto;
  }
  #navigation #navbar-menu {
    display: flex;
    width: 100%;
    justify-content: end;
    gap: 2.5rem;
  }
  #navigation #navbar-menu ul {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  #navigation #navbar-menu ul li {
    list-style: none;
    margin-left: 1.5rem;
    padding-top: 0.3rem;
  }
  #navigation #navbar-menu ul li a {
    text-decoration: none;
    font-size: clamp(1.4rem, 1.5vw, 1.6rem);
    position: relative;
    z-index: 1;
    color: var(--headerColor);
    transition: color 0.3s;
  }
  #navigation #navbar-menu ul li a:hover {
    color: var(--primary-2);
  }
  #navigation #navbar-menu ul li a:before {
    content: '';
    position: absolute;
    display: block;
    height: 0.3rem;
    border-radius: 0.3rem;
    background: var(--primary-2);
    opacity: 1;
    bottom: -0.5rem;
    z-index: -1;
    left: 0;
    width: 0%;
    transition: width 0.3s;
  }
  #navigation #navbar-menu ul li a:hover:before {
    width: 100%;
  }
  #navigation #navbar-menu ul li a.active {
    font-weight: bold;
  }
  #navigation #navbar-menu ul li a.active:before {
    width: 100%;
    height: 0.3rem;
    background: var(--primary-2);
    opacity: 1;
    bottom: -0.5rem;
    border-radius: 0.3rem;
    left: 0rem;
    right: -0.6rem;
    z-index: -1;
    transition: bottom 0.3s;
  }
  #navigation #navbar-menu .button-primary {
    background-color: var(--primary);
    padding: 2rem 3.1rem;
    line-height: 0rem;
    max-width: 17rem;
  }
  #navigation .hamburger-menu {
    display: none;
  }
  #navigation #navbar-menu ul li.dropdown {
    position: relative;
  }
  #navigation #navbar-menu ul li.dropdown .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    min-width: 27.4rem;
    z-index: 1000;
    padding: 1rem 2rem;
    border-radius: 0 0 30px 30px;
  }
  #navigation #navbar-menu ul li.dropdown .dropdown-content li {
    list-style: none;
    padding: 1rem;
  }
  #navigation #navbar-menu ul li.dropdown .dropdown-content li a {
    text-decoration: none;
    color: black;
    font-size: 1.5rem;
  }
  #navigation #navbar-menu ul li.dropdown .dropdown-content li a:hover {
    color: var(--primary-2);
  }
  #navigation #navbar-menu ul li.dropdown:hover .dropdown-content {
    display: block;
  }
}
/* Desktop - 1215px */
@media only screen and (min-width: 1264px) {
  #navigation .logo {
    margin-right: 3.2rem;
  }
  #navigation #navbar-menu {
    gap: 3.5rem;
  }
  #navigation #navbar-menu ul li {
    margin-left: 3rem;
  }
  #navigation #navbar-menu .button-primary {
    max-width: 23.8rem;
    /* BUTTON ICON */
  }
  #navigation #navbar-menu .button-primary:hover {
    background-color: var(--primaryDark);
    font-weight: bold;
    transform: scale(1.05);
    color: var(--bodyTextColorWhite);
  }
  #navigation #navbar-menu .button-primary:hover:before {
    width: 100%;
    border-radius: 5px;
  }
  #navigation #navbar-menu .button-primary .cs-img {
    height: 100%;
    transition: filter 0.3s ease;
    /* Smooth transition for the color change */
  }
  #navigation #navbar-menu .button-primary:hover .cs-img {
    filter: brightness(0) invert(1);
    /* Changes the arrow to white */
  }
}
/* PROJECTS HERO */
/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #projects-hero {
    padding-top: clamp(6rem, 16vw, 20rem);
    /* TEXT CONTAINER */
    /* PICTURE */
  }
  #projects-hero .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* row-gap: clamp(3rem,3vw,7.4rem); */
  }
  #projects-hero .cs-left {
    max-width: 64.8rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: var(--sectionPadding);
  }
  #projects-hero .cs-left .p3-caps {
    padding-bottom: 2.4rem;
  }
  #projects-hero .cs-right {
    width: 100%;
    max-width: 48.2rem;
    min-height: clamp(40rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
    border-radius: 10px;
    overflow: hidden;
  }
  #projects-hero .cs-right .cs-picture img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #projects-hero {
    padding-top: 0rem;
    width: 100%;
    display: flex;
    justify-content: center;
    /* TEXT CONTAINER */
    /* PICTURE */
  }
  #projects-hero .cs-container {
    flex-flow: row-reverse;
    justify-content: space-between;
    align-items: stretch;
    gap: 3.25rem;
    width: 100%;
    height: 70vw;
    max-width: 180rem;
    max-height: 100rem;
    margin-right: 0;
    margin-left: 0;
  }
  #projects-hero .cs-left {
    width: 50%;
    margin-left: auto;
    display: flex;
    align-items: left;
  }
  #projects-hero .cs-left p {
    max-width: 43rem;
  }
  #projects-hero .cs-right {
    max-width: none;
    max-height: none;
    width: 50%;
    height: 100%;
    position: relative;
    padding: 0;
    margin: 0;
    border-radius: 0px;
    overflow: none;
  }
  #projects-hero .cs-right .cs-picture img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}
/* FINAL CALL TO ACTION */
@media only screen and (min-width: 0em) {
  #cta {
    padding: var(--sectionPadding);
    padding-bottom: clamp(2rem, 5.5vw, 0px);
    background-color: #F7F7F7;
    z-index: 99999;
    position: relative;
    /* black background */
  }
  #cta .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: clamp(3.2rem, 8.8vw, 4.6rem);
    border-radius: 20px;
    background-color: white;
    background-image: linear-gradient(to right, #95B7FF, #4629FF);
    padding: clamp(3.2rem, 8.8vw, 6rem) 1.6rem clamp(3.2rem, 8.8vw, 6rem) 1.6rem;
    z-index: 1;
  }
  #cta::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 32%;
    background-color: var(--footer);
    z-index: -1;
  }
  #cta h2 {
    color: white;
    text-align: center;
    padding-bottom: 0px;
  }
  #cta .color-change {
    color: #FFE400;
  }
  #cta .cs-button-solid {
    max-width: none;
    position: relative;
    /* Ensure it is above the after element */
    z-index: 3;
    /* Ensure it is clickable */
  }
}
/* DESKTOP 1024 */
@media only screen and (min-width: 1024px) {
  #cta .container {
    max-width: 124rem;
  }
  #cta::after {
    height: 40%;
  }
}
/* -------------------------- */
/*      FOOTER          */
/* -------------------------- */
/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #vn-footer-1 .cs-top {
    padding: var(--sectionPadding);
    padding-bottom: clamp(8rem, 21.3vw, 10rem);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    z-index: 10;
    background-color: var(--footer-bg-color);
  }
  #vn-footer-1 .cs-top .cs-container {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 2.4rem;
    row-gap: 5.6rem;
  }
  #vn-footer-1 .cs-top .cs-logo-group {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #vn-footer-1 .cs-top .cs-logo {
    width: 25.7rem;
    height: auto;
    display: block;
    margin: 0 0 clamp(2rem, 3.2vw, 2.4rem) 0;
  }
  #vn-footer-1 .cs-top .cs-logo-img {
    width: 100%;
    height: auto;
  }
  #vn-footer-1 .cs-top .cs-logo-img.dark {
    display: none;
  }
  #vn-footer-1 .cs-top .cs-text {
    font-size: 1.6rem;
    line-height: 1.5em;
    margin: 0 0 clamp(2rem, 4.8vw, 2.8rem);
    width: 124.8%;
    max-width: 30rem;
    color: var(--footer-text-color);
  }
  #vn-footer-1 .cs-top .cs-text.subtitle {
    font-family: 'Barlow Semi Condensed', sans-serif;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: normal;
  }
  #vn-footer-1 .cs-top .cs-link {
    font-size: 1.4rem;
    line-height: 1.5em;
    text-decoration: none;
    margin: 0;
    color: var(--footer-text-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;
    transition: color 0.3s;
  }
  #vn-footer-1 .cs-top .cs-link:hover {
    color: var(--footer-hover-color);
  }
  #vn-footer-1 .cs-top .cs-nav {
    width: 72%;
    max-width: 18rem;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.6rem;
  }
  #vn-footer-1 .cs-top .cs-nav-li {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  #vn-footer-1 .cs-top .cs-header {
    font-size: 2rem;
    line-height: 1.2em;
    font-weight: 700;
    margin-bottom: 0.8rem;
    text-transform: uppercase;
    font-family: 'Barlow Semi Condensed', sans-serif;
    color: var(--footer-text-color);
    position: relative;
    display: block;
  }
  #vn-footer-1 .cs-top .header-margin-bottom-16px {
    margin-bottom: 1.6rem;
  }
  #vn-footer-1 .cs-top .cs-nav-link {
    font-size: 1.4rem;
    text-decoration: none;
    line-height: 1.5em;
    width: auto;
    color: var(--footer-text-color);
    position: relative;
    display: inline-block;
    transition: color 0.3s;
  }
  #vn-footer-1 .cs-top .cs-nav-link:before {
    content: "";
    width: 0%;
    height: 0.2rem;
    background: currentColor;
    opacity: 1;
    position: absolute;
    display: block;
    bottom: -0.2rem;
    left: 0;
    transition: width 0.3s;
  }
  #vn-footer-1 .cs-top .cs-nav-link:hover {
    color: var(--footer-hover-color);
    font-weight: 600;
  }
  #vn-footer-1 .cs-top .cs-nav-link:hover:before {
    width: 100%;
  }
  #vn-footer-1 .cs-top .services-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1.6rem;
    margin-left: 0px;
    color: var(--footer-text-color);
  }
  #vn-footer-1 .cs-top .services-list .p3 {
    display: flex;
    align-items: center;
    color: var(--footer-text-color);
    font-size: 1.4rem;
    text-decoration: none;
    line-height: 1.5em;
  }
  #vn-footer-1 .cs-top .services-list .p3 a {
    color: var(--footer-text-color);
    list-style: none;
    text-decoration: none;
    font-size: 1.4rem;
  }
  #vn-footer-1 .cs-top .services-list .p3 a:before {
    content: "";
    width: 0%;
    height: 0.2rem;
    background: currentColor;
    opacity: 1;
    position: absolute;
    display: block;
    bottom: -0.2rem;
    left: 0;
    transition: width 0.3s;
  }
  #vn-footer-1 .cs-top .services-list .p3 a:hover {
    color: var(--footer-hover-color);
    font-weight: 600;
  }
  #vn-footer-1 .cs-top .services-list .p3 a:hover:before {
    width: 100%;
  }
  #vn-footer-1 .cs-top .services-list .p3 i {
    margin-right: 1.2rem;
  }
  #vn-footer-1 .cs-top .services-list .p3 picture {
    font-size: clamp(1.6rem, 1.2vw, 1.8rem);
    display: flex;
    align-items: center;
    filter: brightness(0) invert(1);
    margin-right: 1rem;
    margin-top: 0.5rem;
    height: auto;
  }
  #vn-footer-1 .cs-top .services-list .p3 picture img {
    width: 100%;
    height: auto;
  }
  #vn-footer-1 .cs-top .cs-nav.kontakt {
    max-width: 27rem;
  }
  #vn-footer-1 .cs-bottom {
    background-color: #435A80;
    margin: auto;
    padding: clamp(2rem, 4.8vw, 3.2rem) 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 3.6rem;
    row-gap: 1.2rem;
  }
  #vn-footer-1 .cs-copyright,
  #vn-footer-1 .cs-copyright-link {
    font-size: 1.4rem;
    line-height: 1.5em;
    color: var(--footer-text-color);
    max-width: 128rem;
    margin: auto;
  }
  #vn-footer-1 .cs-copyright {
    text-align: center;
    width: 100%;
  }
  #vn-footer-1 .cs-copyright-link {
    text-decoration: none;
    transition: color 0.3s;
  }
  #vn-footer-1 .cs-copyright-link b:before {
    content: "";
    width: 0%;
    height: 0.2rem;
    background: currentColor;
    opacity: 1;
    position: absolute;
    display: block;
    bottom: -0.2rem;
    left: 0;
    transition: width 0.3s;
  }
  #vn-footer-1 .cs-copyright-link b:hover {
    color: var(--footer-hover-color);
    text-decoration: underline;
  }
  #vn-footer-1 .cs-copyright-link b:hover:before {
    width: 100%;
  }
  #vn-footer-1 .cs-copyright-link:hover {
    color: var(--footer-hover-color);
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 768px) {
  #vn-footer-1 .cs-container {
    row-gap: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 8.8rem;
    row-gap: 4rem;
  }
  #vn-footer-1 .cs-nav {
    width: auto;
  }
  #vn-footer-1 .cs-bottom {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  #vn-footer-1 .cs-copyright {
    text-align: left;
    width: auto;
    margin-right: auto;
  }
  #vn-footer-1 .cs-floater {
    display: block;
  }
}
/* Small Desktop - 1024px */
@media only screen and (min-width: 1077px) {
  #vn-footer-1 .cs-container {
    max-width: 128rem;
    flex-wrap: nowrap;
    justify-content: flex-end;
    column-gap: clamp(4rem, 9.6vw, 8.4rem);
  }
  #vn-footer-1 .cs-logo-group {
    width: 48%;
    max-width: 35.7rem;
    margin-right: auto;
    align-items: flex-start !important;
  }
  #vn-footer-1 .cs-text {
    width: 100%;
  }
  #vn-footer-1 .cs-graphic {
    display: block;
  }
}
/* -------------------------- */
/*      WEBSITE PARTS          */
/* -------------------------- */
/* -------------------------- */
/*      INTERIOR HERO          */
/* -------------------------- */
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #interior-hero {
    padding: var(--sectionPadding);
    padding-top: clamp(8rem, 21.3vw, 13rem);
    padding-top: clamp(8rem, 5.3vw, 13rem);
    padding-bottom: clamp(0rem, 3vw, 4rem);
    display: flex;
    justify-content: center;
    background-color: var(--backround-1);
    position: relative;
  }
  #interior-hero .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    z-index: 1;
    gap: 1rem;
    position: relative;
    display: flex;
    justify-content: center;
  }
  #interior-hero .h1-with-smaller-text {
    line-height: 0.8em;
  }
  #interior-hero .container-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 76.9rem;
    text-align: left;
    z-index: 2;
  }
  #interior-hero .cs-backround {
    z-index: -2;
    width: 100%;
    pointer-events: none;
    /* Prevents interaction */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: clamp(8.2rem, 17vw, 12rem);
  }
  #interior-hero .cs-backround img {
    width: 100%;
    height: auto;
  }
  .massage-interior-hero {
    gap: clamp(1rem, 2.6vw, 4rem) !important;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #interior-hero .cs-container {
    max-width: 124rem;
  }
}
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #interior-hero .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    z-index: 1;
    gap: 4rem;
    position: relative;
    display: flex;
    justify-content: center;
  }
  #interior-hero .cs-container .h1-with-smaller-text {
    line-height: 0.8em;
  }
  #interior-hero .cs-container .container-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 76.9rem;
    text-align: center;
    z-index: 2;
  }
  #interior-hero .cs-container .cs-backround {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -2;
    width: 100%;
    width: clamp(10.2rem, 21vw, 19rem);
    pointer-events: none;
    /* Prevents interaction */
  }
  #interior-hero .cs-container .cs-backround img {
    width: 100%;
    height: auto;
  }
}
/*-- -------------------------- -->
<---     WIE FUNKTIONIERT ES    -->
<--- -------------------------- -*/
#banner-with-margins {
  padding: var(--sectionPadding);
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
#banner-with-margins .cs-container {
  width: 100%;
  max-width: 124rem;
  margin: auto;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: clamp(3.2rem, 8.8vw, 4.6rem);
  background-color: var(--backround-2);
  border-radius: 30px;
  padding-top: clamp(4rem, 10vw, 6rem);
  padding-bottom: clamp(4rem, 10vw, 6rem);
}
#banner-with-margins h2 {
  padding-bottom: clamp(3.2rem, 8.89vw, 4.2rem);
  text-align: center;
  max-width: 39.5rem;
}
#banner-with-margins .cs-wrapper {
  text-align: center;
  padding-right: clamp(1rem, 2.77vw, 3rem);
  padding-left: clamp(1rem, 2.77vw, 3rem);
  max-width: 50rem;
}
#banner-with-margins .healing-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
#banner-with-margins .cs-graphic {
  display: none;
}
#banner-with-margins .healing-list li {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* Ajustar espacio entre el ícono y el texto */
  align-items: flex-start;
  text-align: start;
}
#banner-with-margins .healing-list li picture {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: var(--primary-1);
  border-radius: 50%;
  flex-shrink: 0;
  /* Evita que la imagen se encoja */
  margin-top: 1rem;
}
#banner-with-margins .healing-list li .cs-img {
  width: auto;
  height: auto;
}
#banner-with-margins .healing-list li p.p2 {
  font-size: clamp(1.6rem, 4vw, 2rem);
  color: var(--bodyTextColor);
  margin: 0;
}
#banner-with-margins .healing-list li p.p2 b {
  color: var(--primary-1);
  font-weight: bold;
}
/* Tablet - 768px */
@media only screen and (min-width: 48em) {
  #banner-with-margins .cs-container {
    justify-content: space-between;
  }
  #banner-with-margins .healing-content {
    max-width: 45%;
  }
  #banner-with-margins .healing-background {
    max-width: 50rem;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 64em) {
  #banner-with-margins .cs-container {
    max-width: 120rem;
  }
  #banner-with-margins .cs-container .cs-wrapper {
    display: flex;
    flex-direction: row;
    max-width: none;
    width: 100%;
    align-items: center;
    gap: clamp(6rem, 5.8vw, 10.1rem);
    justify-content: center;
  }
  #banner-with-margins .cs-container .cs-wrapper h2 {
    padding-bottom: 0px;
  }
  #banner-with-margins .cs-container .cs-graphic {
    display: inline;
    position: absolute;
    width: 29.4rem;
    height: auto;
    bottom: 0;
    left: 1em;
    z-index: 1;
  }
  #banner-with-margins .cs-container img {
    height: auto;
    width: 100%;
  }
  .healing-content {
    max-width: 40%;
  }
  .healing-background {
    max-width: 55rem;
  }
}
/*-- -------------------------- -->
<---       CTA-WITH-MAP    -->
<--- -------------------------- -*/
#cta-with-map {
  padding: var(--sectionPadding);
  /* PICTURE */
  /* LIST */
  /* Tablet - 768px */
  /* Desktop - 1024px */
}
#cta-with-map .cs-container {
  width: 100%;
  max-width: 60rem;
  margin: auto;
  display: flex;
  flex-direction: column-reverse;
  gap: clamp(3.2rem, 6.6vw, 4.8rem);
  align-items: center;
}
#cta-with-map .flex-container {
  gap: 3.2rem;
  display: flex;
  padding-top: 2.8rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
#cta-with-map .cs-left {
  height: auto;
  position: relative;
  align-self: center;
  max-width: 75rem;
  width: 100%;
}
#cta-with-map .cs-left img {
  border-radius: 30px;
  max-height: 34.9rem;
  width: 100%;
  height: auto;
  display: block;
}
#cta-with-map .container-map {
  border-radius: 30px;
  max-height: 34.9rem;
  width: 100%;
}
#cta-with-map h2 {
  padding-bottom: clamp(2.4rem, 6.6vw, 3.2rem);
}
#cta-with-map .services-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.8rem;
  margin-left: 0px;
}
#cta-with-map .services-list li {
  font-size: clamp(1.6rem, 1.2vw, 1.8rem);
  display: flex;
  align-items: center;
}
#cta-with-map .services-list li picture {
  margin-right: 1rem;
  margin-top: 0.5rem;
  height: auto;
}
#cta-with-map .services-list li picture img {
  width: 100%;
  height: auto;
}
#cta-with-map .services-list li i {
  margin-right: 1.8rem;
}
#cta-with-map .services-list li a {
  color: inherit;
  /* Use the same color as the surrounding text */
  text-decoration: none;
  /* Remove the underline */
}
#cta-with-map .services-list li a:hover,
#cta-with-map .services-list li a:focus {
  text-decoration: underline;
  /* Optionally add underline on hover/focus */
  color: var(--linkHoverColor);
  /* Optionally change color on hover/focus */
}
#cta-with-map .services-list .word-break {
  white-space: normal;
  word-break: normal;
  display: flex;
  flex-direction: row;
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
#cta-with-map .list-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
  margin-bottom: 5.2rem;
}
#cta-with-map .list-group {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
#cta-with-map .list-item {
  max-width: 33.4rem;
}
#cta-with-map .list-item h3 {
  display: flex;
  align-items: center;
  gap: 1.1rem;
  padding-bottom: clamp(0.8rem, 2.2vw, 1.4rem);
}
#cta-with-map .cs-button-solid {
  max-width: 34.5rem;
  margin-top: 2rem;
}
@media only screen and (min-width: 610px) {
  #cta-with-map .list-wrapper {
    flex-direction: row;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 1024px) {
  #cta-with-map .cs-container {
    flex-direction: row-reverse;
    align-items: flex-start;
    max-width: 124rem;
    justify-content: space-between;
  }
  #cta-with-map .flex-container {
    display: flex;
    justify-content: center;
    padding-top: 2.8rem;
    flex-direction: row;
  }
  #cta-with-map .title-h4 {
    align-self: center;
    max-width: 28rem;
  }
  #cta-with-map .cs-right {
    max-width: 38.5rem;
  }
}
/*-- -------------------------- -->
<---           LIST             -->
<--- -------------------------- -*/
.cs-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.cs-list li {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* Ajustar espacio entre el ícono y el texto */
}
.cs-list li picture,
.cs-list li .cs-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: var(--primary-1);
  border-radius: 50%;
  flex-shrink: 0;
  /* Evita que la imagen se encoja */
}
.cs-list li .cs-img {
  width: auto;
  height: auto;
}
.cs-list li p.p2 {
  font-size: clamp(1.6rem, 4vw, 2rem);
  color: var(--bodyTextColor);
  margin: 0;
}
.cs-list li p.p2 b {
  color: var(--primary-1);
  font-weight: bold;
}
/*-- -------------------------- -->
<---           IMPRESSUM            -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0px) {
  #impressum {
    padding: var(--sectionPadding);
  }
  #impressum .cs-container {
    max-width: 55rem;
    width: 100%;
    margin: auto;
  }
  #impressum .cs-container .padding-bottom-60px {
    padding-bottom: 6rem;
  }
}
/* DESKTP - 1024px */
@media only screen and (min-width: 1024px) {
  #impressum {
    padding: var(--sectionPadding);
  }
  #impressum .cs-container {
    max-width: 119rem;
    width: 100%;
    margin: auto;
  }
}
/*-- -------------------------- -->
<---          ERROR 404           -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0px) {
  #error-404 {
    padding: var(--sectionPadding);
    padding-top: 4rem !important;
  }
  #error-404 .cs-container {
    max-width: 55rem;
    width: 100%;
    margin: auto;
    min-height: 70vw;
  }
  #error-404 .cs-container .padding-bottom-60px {
    padding-bottom: 6rem;
  }
  #error-404 .cs-container .title-h1,
  #error-404 .cs-container .p2 {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
  #error-404 .cs-container .title-h1 a,
  #error-404 .cs-container .p2 a {
    color: var(--bodyTextColor);
    font-weight: bold;
  }
  #error-404 .cs-container .flex-row {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }
  #error-404 .cs-container .title-big {
    font-size: 20rem;
  }
}
/* DESKTP - 1024px */
@media only screen and (min-width: 1024px) {
  #error-404 {
    padding: var(--sectionPadding);
  }
  #error-404 .cs-container {
    max-width: 119rem;
    width: 100%;
    margin: auto;
  }
}
/*-- -------------------------- -->
<---                            -->
<---   SBS  Shared Styles Mixin -->
<---                            -->
<--- -------------------------- -*/
/* Desktop Specific Mixin */
/*-- -------------------------- -->
<---     SBS  normal    -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #sbs-normal {
    padding: var(--sectionPadding);
    padding-top: clamp(3rem, 10.3vw, 12rem);
    /* PICTURE */
    /* LEFT CONTENT TXT */
  }
  #sbs-normal .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: clamp(5.2rem, 3vw, 7.4rem);
  }
  #sbs-normal .cs-right {
    width: 100%;
    max-width: 48.2rem;
    min-height: clamp(25rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
    border-radius: 30px;
    overflow: hidden;
    max-height: 30rem;
  }
  #sbs-normal .cs-picture img {
    width: 100%;
    height: auto;
    display: block;
  }
  #sbs-normal .cs-left {
    max-width: clamp(50.7rem, 49.5vw, 60.7rem);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #sbs-normal .cs-title {
    width: 100%;
    text-align: left;
    color: var(--headercolor);
    position: relative;
    padding-bottom: clamp(2rem, 6.7vw, 4rem);
  }
  #sbs-normal .txt-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  #sbs-normal .p2,
  #sbs-normal .p3 {
    padding-bottom: 1.6rem;
  }
  #sbs-normal .padding-bottom-32px {
    padding-bottom: clamp(2.4rem, 6.4vw, 3.2rem);
  }
  #sbs-normal .services-list {
    list-style: none;
    font-weight: 600;
    line-height: 30;
    margin-bottom: 3rem;
  }
  #sbs-normal .services-list li {
    font-size: clamp(1.6rem, 1.2vw, 1.8rem);
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
  }
  #sbs-normal .services-list li picture {
    margin-right: 1.5rem;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #sbs-normal {
    /* PICTURE */
  }
  #sbs-normal .cs-container {
    flex-flow: row;
    justify-content: space-between;
    gap: clamp(3.25rem, 3.1vw, 8rem);
    max-width: 124rem;
  }
  #sbs-normal .cs-right {
    max-width: 54.9rem;
    max-height: 62.7rem;
  }
  #sbs-normal .sbs-picture-max-height {
    max-height: 60rem;
  }
}
/*-- -------------------------- -->
<---     SBS-NORMAL-2    -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #sbs-normal-2 {
    padding: var(--sectionPadding);
    padding-top: clamp(3rem, 10.3vw, 12rem);
    /* PICTURE */
    /* LEFT CONTENT TXT */
  }
  #sbs-normal-2 .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: clamp(5.2rem, 3vw, 7.4rem);
  }
  #sbs-normal-2 .cs-right {
    width: 100%;
    max-width: 48.2rem;
    min-height: clamp(25rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
    border-radius: 30px;
    overflow: hidden;
    max-height: 30rem;
  }
  #sbs-normal-2 .cs-picture img {
    width: 100%;
    height: auto;
    display: block;
  }
  #sbs-normal-2 .cs-left {
    max-width: clamp(50.7rem, 49.5vw, 60.7rem);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #sbs-normal-2 .cs-title {
    width: 100%;
    text-align: left;
    color: var(--headercolor);
    position: relative;
    padding-bottom: clamp(2rem, 6.7vw, 4rem);
  }
  #sbs-normal-2 .txt-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  #sbs-normal-2 .p2,
  #sbs-normal-2 .p3 {
    padding-bottom: 1.6rem;
  }
  #sbs-normal-2 .padding-bottom-32px {
    padding-bottom: clamp(2.4rem, 6.4vw, 3.2rem);
  }
  #sbs-normal-2 .services-list {
    list-style: none;
    font-weight: 600;
    line-height: 30;
    margin-bottom: 3rem;
  }
  #sbs-normal-2 .services-list li {
    font-size: clamp(1.6rem, 1.2vw, 1.8rem);
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
  }
  #sbs-normal-2 .services-list li picture {
    margin-right: 1.5rem;
  }
}
/* Desktop - 1128px */
@media only screen and (min-width: 1024px) {
  #sbs-normal-2 {
    /* PICTURE */
  }
  #sbs-normal-2 .cs-container {
    flex-flow: row;
    justify-content: space-between;
    gap: clamp(3.25rem, 3.1vw, 8rem);
    max-width: 124rem;
  }
  #sbs-normal-2 .cs-right {
    max-width: 54.9rem;
    max-height: 62.7rem;
  }
  #sbs-normal-2 .sbs-picture-max-height {
    max-height: 60rem;
  }
}
/*-- -------------------------- -->
<---     SBS-NORMAL-3    -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #sbs-normal-3 {
    padding: var(--sectionPadding);
    padding-top: clamp(3rem, 10.3vw, 12rem);
    /* PICTURE */
    /* LEFT CONTENT TXT */
  }
  #sbs-normal-3 .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: clamp(5.2rem, 3vw, 7.4rem);
  }
  #sbs-normal-3 .cs-right {
    width: 100%;
    max-width: 48.2rem;
    min-height: clamp(25rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
    border-radius: 30px;
    overflow: hidden;
    max-height: 30rem;
  }
  #sbs-normal-3 .cs-picture img {
    width: 100%;
    height: auto;
    display: block;
  }
  #sbs-normal-3 .cs-left {
    max-width: clamp(50.7rem, 49.5vw, 60.7rem);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #sbs-normal-3 .cs-title {
    width: 100%;
    text-align: left;
    color: var(--headercolor);
    position: relative;
    padding-bottom: clamp(2rem, 6.7vw, 4rem);
  }
  #sbs-normal-3 .txt-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  #sbs-normal-3 .p2,
  #sbs-normal-3 .p3 {
    padding-bottom: 1.6rem;
  }
  #sbs-normal-3 .padding-bottom-32px {
    padding-bottom: clamp(2.4rem, 6.4vw, 3.2rem);
  }
  #sbs-normal-3 .services-list {
    list-style: none;
    font-weight: 600;
    line-height: 30;
    margin-bottom: 3rem;
  }
  #sbs-normal-3 .services-list li {
    font-size: clamp(1.6rem, 1.2vw, 1.8rem);
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
  }
  #sbs-normal-3 .services-list li picture {
    margin-right: 1.5rem;
  }
}
/* Desktop - 1128px */
@media only screen and (min-width: 1024px) {
  #sbs-normal-3 {
    /* PICTURE */
  }
  #sbs-normal-3 .cs-container {
    flex-flow: row;
    justify-content: space-between;
    gap: clamp(3.25rem, 3.1vw, 8rem);
    max-width: 124rem;
  }
  #sbs-normal-3 .cs-right {
    max-width: 54.9rem;
    max-height: 62.7rem;
  }
  #sbs-normal-3 .sbs-picture-max-height {
    max-height: 60rem;
  }
}
/*-- -------------------------- -->
<---     SBS reverse    -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #sbs-normal-reverse {
    padding: var(--sectionPadding);
    padding-top: clamp(3rem, 10.3vw, 12rem);
    /* PICTURE */
    /* LEFT CONTENT TXT */
    background: linear-gradient(358deg, var(--backround-1) 100%, #ffffff 10%);
    padding-top: clamp(3rem, 10.3vw, 14.2rem);
    /* Reverse the order of the content */
  }
  #sbs-normal-reverse .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: clamp(5.2rem, 3vw, 7.4rem);
  }
  #sbs-normal-reverse .cs-right {
    width: 100%;
    max-width: 48.2rem;
    min-height: clamp(25rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
    border-radius: 30px;
    overflow: hidden;
    max-height: 30rem;
  }
  #sbs-normal-reverse .cs-picture img {
    width: 100%;
    height: auto;
    display: block;
  }
  #sbs-normal-reverse .cs-left {
    max-width: clamp(50.7rem, 49.5vw, 60.7rem);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #sbs-normal-reverse .cs-title {
    width: 100%;
    text-align: left;
    color: var(--headercolor);
    position: relative;
    padding-bottom: clamp(2rem, 6.7vw, 4rem);
  }
  #sbs-normal-reverse .txt-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  #sbs-normal-reverse .p2,
  #sbs-normal-reverse .p3 {
    padding-bottom: 1.6rem;
  }
  #sbs-normal-reverse .padding-bottom-32px {
    padding-bottom: clamp(2.4rem, 6.4vw, 3.2rem);
  }
  #sbs-normal-reverse .services-list {
    list-style: none;
    font-weight: 600;
    line-height: 30;
    margin-bottom: 3rem;
  }
  #sbs-normal-reverse .services-list li {
    font-size: clamp(1.6rem, 1.2vw, 1.8rem);
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
  }
  #sbs-normal-reverse .services-list li picture {
    margin-right: 1.5rem;
  }
  #sbs-normal-reverse .cs-container {
    flex-direction: column;
  }
}
/* TABLET - 768px */
@media only screen and (min-width: 768px) {
  #sbs-normal-reverse {
    background: linear-gradient(358deg, var(--backround-1) 97%, #ffffff 10%);
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #sbs-normal-reverse {
    /* PICTURE */
    background: linear-gradient(358deg, var(--backround-1) 93%, #ffffff 10%);
    /* Reverse the order of the content */
  }
  #sbs-normal-reverse .cs-container {
    flex-flow: row;
    justify-content: space-between;
    gap: clamp(3.25rem, 3.1vw, 8rem);
    max-width: 124rem;
  }
  #sbs-normal-reverse .cs-right {
    max-width: 54.9rem;
    max-height: 62.7rem;
  }
  #sbs-normal-reverse .sbs-picture-max-height {
    max-height: 60rem;
  }
  #sbs-normal-reverse .cs-container {
    flex-direction: row-reverse;
  }
}
/*-- -------------------------- -->
<---     SBS-REVERSE-2    -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #sbs-normal-reverse-2 {
    padding: var(--sectionPadding);
    padding-top: clamp(3rem, 10.3vw, 12rem);
    /* PICTURE */
    /* LEFT CONTENT TXT */
    /* Reverse the order of the content */
  }
  #sbs-normal-reverse-2 .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: clamp(5.2rem, 3vw, 7.4rem);
  }
  #sbs-normal-reverse-2 .cs-right {
    width: 100%;
    max-width: 48.2rem;
    min-height: clamp(25rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
    border-radius: 30px;
    overflow: hidden;
    max-height: 30rem;
  }
  #sbs-normal-reverse-2 .cs-picture img {
    width: 100%;
    height: auto;
    display: block;
  }
  #sbs-normal-reverse-2 .cs-left {
    max-width: clamp(50.7rem, 49.5vw, 60.7rem);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #sbs-normal-reverse-2 .cs-title {
    width: 100%;
    text-align: left;
    color: var(--headercolor);
    position: relative;
    padding-bottom: clamp(2rem, 6.7vw, 4rem);
  }
  #sbs-normal-reverse-2 .txt-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  #sbs-normal-reverse-2 .p2,
  #sbs-normal-reverse-2 .p3 {
    padding-bottom: 1.6rem;
  }
  #sbs-normal-reverse-2 .padding-bottom-32px {
    padding-bottom: clamp(2.4rem, 6.4vw, 3.2rem);
  }
  #sbs-normal-reverse-2 .services-list {
    list-style: none;
    font-weight: 600;
    line-height: 30;
    margin-bottom: 3rem;
  }
  #sbs-normal-reverse-2 .services-list li {
    font-size: clamp(1.6rem, 1.2vw, 1.8rem);
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
  }
  #sbs-normal-reverse-2 .services-list li picture {
    margin-right: 1.5rem;
  }
  #sbs-normal-reverse-2 .cs-container {
    flex-direction: column;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #sbs-normal-reverse-2 {
    /* PICTURE */
    /* Reverse the order of the content */
  }
  #sbs-normal-reverse-2 .cs-container {
    flex-flow: row;
    justify-content: space-between;
    gap: clamp(3.25rem, 3.1vw, 8rem);
    max-width: 124rem;
  }
  #sbs-normal-reverse-2 .cs-right {
    max-width: 54.9rem;
    max-height: 62.7rem;
  }
  #sbs-normal-reverse-2 .sbs-picture-max-height {
    max-height: 60rem;
  }
  #sbs-normal-reverse-2 .cs-container {
    flex-direction: row-reverse;
  }
}
/*-- -------------------------- -->
<---     SBS-REVERSE-3    -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #sbs-normal-reverse-3 {
    padding: var(--sectionPadding);
    padding-top: clamp(3rem, 10.3vw, 12rem);
    /* PICTURE */
    /* LEFT CONTENT TXT */
    /* Reverse the order of the content */
  }
  #sbs-normal-reverse-3 .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: clamp(5.2rem, 3vw, 7.4rem);
  }
  #sbs-normal-reverse-3 .cs-right {
    width: 100%;
    max-width: 48.2rem;
    min-height: clamp(25rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
    border-radius: 30px;
    overflow: hidden;
    max-height: 30rem;
  }
  #sbs-normal-reverse-3 .cs-picture img {
    width: 100%;
    height: auto;
    display: block;
  }
  #sbs-normal-reverse-3 .cs-left {
    max-width: clamp(50.7rem, 49.5vw, 60.7rem);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #sbs-normal-reverse-3 .cs-title {
    width: 100%;
    text-align: left;
    color: var(--headercolor);
    position: relative;
    padding-bottom: clamp(2rem, 6.7vw, 4rem);
  }
  #sbs-normal-reverse-3 .txt-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  #sbs-normal-reverse-3 .p2,
  #sbs-normal-reverse-3 .p3 {
    padding-bottom: 1.6rem;
  }
  #sbs-normal-reverse-3 .padding-bottom-32px {
    padding-bottom: clamp(2.4rem, 6.4vw, 3.2rem);
  }
  #sbs-normal-reverse-3 .services-list {
    list-style: none;
    font-weight: 600;
    line-height: 30;
    margin-bottom: 3rem;
  }
  #sbs-normal-reverse-3 .services-list li {
    font-size: clamp(1.6rem, 1.2vw, 1.8rem);
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
  }
  #sbs-normal-reverse-3 .services-list li picture {
    margin-right: 1.5rem;
  }
  #sbs-normal-reverse-3 .cs-container {
    flex-direction: column;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #sbs-normal-reverse-3 {
    /* PICTURE */
    /* Reverse the order of the content */
  }
  #sbs-normal-reverse-3 .cs-container {
    flex-flow: row;
    justify-content: space-between;
    gap: clamp(3.25rem, 3.1vw, 8rem);
    max-width: 124rem;
  }
  #sbs-normal-reverse-3 .cs-right {
    max-width: 54.9rem;
    max-height: 62.7rem;
  }
  #sbs-normal-reverse-3 .sbs-picture-max-height {
    max-height: 60rem;
  }
  #sbs-normal-reverse-3 .cs-container {
    flex-direction: row-reverse;
  }
}
/*-- -------------------------- -->
<---     CONTAINER OF 2 BUTTONS    -->
<--- -------------------------- -*/
@media only screen and (min-width: 0em) {
  .container-btns {
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
  }
  .hypens-auto {
    hyphens: auto;
  }
}
@media only screen and (min-width: 768px) {
  .container-btns {
    flex-direction: row;
  }
}
/*-- -------------------------- -->
<---          Reviews           -->
<--- -------------------------- -*/
@media only screen and (min-width: 0em) {
  #reviews {
    background-color: var(--backround-3);
    padding: var(--sectionPadding);
  }
  #reviews .reviews-carousel {
    position: relative;
    width: 100%;
  }
  #reviews .reviews-carousel .list {
    display: flex;
    gap: 3.2rem;
    padding: 16px;
    list-style: none;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    /* Hide scrollbar */
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  #reviews .reviews-carousel .list::-webkit-scrollbar {
    display: none;
  }
  #reviews .reviews-carousel .item {
    flex-shrink: 0;
    width: calc(33.33% - 3.2rem);
    /* 3 items visible */
    scroll-snap-align: center;
    min-width: clamp(24.6rem, 65.6vw, 34.6rem);
  }
  #reviews .reviews-carousel .button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: var(--primary);
    border: none;
    display: block;
    align-items: center;
    justify-content: center;
    z-index: 2;
    cursor: pointer;
  }
  #reviews .reviews-carousel .button--previous {
    left: -1rem;
    transform: translate(-50%, -50%);
  }
  #reviews .reviews-carousel .button--next {
    right: -1rem;
    transform: translate(50%, -50%);
  }
  #reviews .cs-container {
    width: 100%;
    max-width: 120rem;
    padding: var(--sectionPadding);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(2.5rem, 7vw, 4rem);
  }
  #reviews .cs-content {
    text-align: center;
    flex-direction: column;
    align-items: flex-start;
  }
  #reviews .title-h2 {
    padding-bottom: 2.2rem;
  }
  #reviews .cs-picture {
    height: 100%;
  }
  #reviews .cs-picture img {
    height: 100%;
  }
  #reviews .cs-card-group {
    width: 100%;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: clamp(2.4rem, 6.67vw, 3.2rem);
  }
  #reviews .icon-client-letter {
    background-color: #8E00DB;
    border-radius: 100%;
    width: 3.7rem;
    height: 3.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.6rem;
  }
  #reviews .icon-client-letter-b {
    background-color: #2800DB;
  }
  #reviews .icon-client-letter-c {
    background-color: #442200;
  }
  #reviews .cs-item {
    list-style: none;
    width: 100%;
    padding: clamp(1.25rem, 3.15vw, 2.5rem);
    background-color: white;
    box-sizing: border-box;
    grid-column: span 12;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    box-shadow: var(--shadow-md);
    border-radius: 1.6rem;
    border: #E2E8F0 1rem;
    display: flex;
    gap: 1.8rem;
  }
  #reviews .cs-flex-group {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
  #reviews .cs-profile {
    width: 3.25rem;
    height: auto;
    border-radius: 50%;
    position: relative;
    display: block;
  }
  #reviews .cs-profile img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  #reviews .cs-name {
    font-size: 1.8rem;
    line-height: 1.2em;
    font-weight: 700;
    color: var(--headerColor);
    display: block;
    text-transform: uppercase;
    font-family: 'Barlow Semi Condensed', sans-serif;
  }
  #reviews .cs-job {
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 400;
    margin: 0;
    color: var(--bodyTextColor);
    display: block;
  }
  #reviews .wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  #reviews .cs-item-stars {
    width: 6.75rem;
    height: auto;
    padding-top: 1rem;
  }
  #reviews .cs-review {
    font-size: clamp(1.1rem, 3vw, 1.6rem);
    line-height: 1.5em;
    margin: 0;
    color: var(--bodyTextColor);
    z-index: 1;
  }
  #reviews .cs-quote {
    width: 5.375rem;
    height: auto;
    z-index: 0;
  }
  #reviews .review {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--headerColor);
    gap: 0.1rem;
  }
  #reviews .review .google-review {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  #reviews .review .google-review .p3 {
    padding-bottom: 0px !important;
  }
  #reviews .review .number {
    font-size: 2.4rem;
    font-weight: bold;
    color: var(--headerColor);
    font-family: 'Barlow Semi Condensed', sans-serif;
  }
  #reviews .review .legend {
    color: var(--headerColor);
    line-height: 1.2em;
    text-align: center;
    font-size: 1.3rem;
  }
  #reviews .divider {
    width: 0.352rem;
    height: 4.8rem;
    background-color: #E2E8F0;
  }
  #reviews .stars-rating {
    display: flex;
    gap: 0.5rem;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 768px) {
  #reviews .cs-item {
    grid-column: span 4;
  }
  #reviews .cs-image {
    height: 3.3rem;
    width: 3.3rem;
  }
}
/*-- -------------------------- -->
<---          MOBILE HIDDEN           -->
<--- -------------------------- -*/
@media only screen and (max-width: 50rem) {
  .mobile-hidden {
    display: none;
  }
}
/* -------------------------- */
/*      COOKIE BANNER         */
/* -------------------------- */
@media only screen and (min-width: 0rem) {
  #cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #2c2c2c;
    color: #fff;
    z-index: 9999;
    font-family: Arial, sans-serif;
    border-top: 1px solid white;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.3);
    /* Top shadow */
    display: flex;
    justify-content: center;
  }
  #cookie-banner .cookie-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: clamp(1rem, 4vw, 1.5rem) clamp(2rem, 6vw, 3rem);
    flex-wrap: wrap;
    max-width: 55rem;
  }
  #cookie-banner .cookie-content .cookie-text-container p {
    line-height: 1.5;
    color: white;
    padding-bottom: 1rem;
  }
  #cookie-banner .cookie-content .cookie-text-container p a {
    text-decoration: underline;
    font-weight: bold;
    color: #fff;
  }
  #cookie-banner .cookie-content .cookie-text-container p a:hover {
    color: #ccc;
  }
  #cookie-banner .cookie-content .cookie-text-container .p3 {
    font-size: 1.3rem;
  }
  #cookie-banner .cookie-content .cookie-buttons {
    display: flex;
    gap: 1rem;
    width: 100%;
  }
  #cookie-banner .cookie-content .cookie-buttons .button-secondary {
    border: 1px solid white;
    background-color: transparent;
    color: white;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 768px) {
  #cookie-banner .cookie-content {
    flex-wrap: nowrap;
    gap: 2rem;
    max-width: 119rem;
  }
  #cookie-banner .cookie-content p {
    font-size: 1.6rem;
  }
}
/* Small Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #cookie-banner .cookie-content {
    justify-content: space-between;
    padding: clamp(1.5rem, 4vw, 2rem) clamp(3rem, 6vw, 4rem);
  }
  #cookie-banner .cookie-content p {
    font-size: 1.8rem;
  }
}
/*# sourceMappingURL=root.css.map */